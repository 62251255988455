<template>
<a-row style="padding-bottom: 40vh">
  <loading-spiner v-if="fetchLoading" />
  <a-col :span="22" :offset="1" v-if="!fetchLoading">
    <a-card
        title="Create Order"
        size="small"
        :style="{textAlign: 'left', marginTop: '10px', backgroundColor: '#FAFAFA'}"
        :headStyle="{textAlign: 'left'}"
    >
      <a-row>
        <h4 style="font-weight: bold; margin-top: 20px;">
            Select type of delivery
          </h4>
          <a-radio-group v-model:value="deliveryType" size="small" style="width: 100%; margin-bottom: 15px;" button-style="solid">
              <a-radio-button value='1' style="width: 50%; text-align: center;">Drop-off</a-radio-button>
              <a-radio-button value='2' style="width: 50%; text-align: center;">Delivery</a-radio-button>
            </a-radio-group>
          <a-typography-paragraph>
            <a-typography-text underline strong> Drop-off</a-typography-text> 
            - If you want to deliver the package to our warehouse yourself
          </a-typography-paragraph>
          <a-typography-paragraph>
            <a-typography-text underline strong> Delivery</a-typography-text>
            - If you want the package to get delivered to our warehouse by any postal service provide us with the tracking number
          </a-typography-paragraph>
          <a-typography-paragraph >
            The delivery address for our warehouse is: <br>
            <a-typography-text underline strong> {{sendInfo.user}} <br/> {{sendInfo.dist}}<br/>{{sendInfo.city}}<CopyOutlined @click="copyToClipboard" /></a-typography-text>
          </a-typography-paragraph>
          <a-button size="small" @click="isShowMap = !isShowMap">
            <EnvironmentOutlined />
            Show on the map
          </a-button>
        <iframe v-if="isShowMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3068.343092367822!2d-75.5694244!3d39.7319434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6fd7e89a2db1f%3A0xc1923bd75ca2755!2zNDAyIDd0aCBBdmUsIFdpbG1pbmd0b24sIERFIDE5ODA1LCDQodCo0JA!5e0!3m2!1sru!2s!4v1732266703062!5m2!1sru!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </a-row>
      <a-row>
        <a-col :span="12">
          <h4 style="font-weight: bold; margin-top: 20px;">
            Select a Warehouse <br />to drop-off
          </h4>
          <a-select
              ref="select"
              v-model:value="warehouse"
              :options="warehouses"
          >
          </a-select>
        </a-col>
        <a-col :span="12">
          <h4 style="font-weight: bold; margin-top: 20px;">
            Select <br /> country
          </h4>
            <a-select
                ref="select"
                v-model:value="country"
                :options="countries"
            >
          </a-select>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <h4 style="font-weight: bold; margin-top: 20px;">
            Select Type <br /> of Parcel
          </h4>
            <a-select
                ref="select"
                v-model:value="parcelType"
                :options="parcelTypes"
            >
          </a-select>
        </a-col>
        <transition name="slide-fade">
        <a-col :span="10" v-show="deliveryType === '2'">
          <h4 style="font-weight: bold; margin-top: 20px;">
            First mile <br /> track number
          </h4>
          <a-input v-model:value="firstMileTrack" placeholder="First mile Track number"/>
        </a-col>
      </transition>
      </a-row>
      <h4 style="font-weight: bold; margin-top: 20px;">
        Choose recipient
      </h4>
        <a-auto-complete
            v-model:value="recipient"
            :options="recipientsList"
            placeholder="Type for search"
            :filter-option="onSearch"
            @select="onSelect"
            style="width: 100%; white-space: break-spaces; word-break: break-word;"
        />
      <div style="text-align: center">
        <span>If you don't have a recipient, you can <router-link to="/editRecipient">add one now</router-link>
        </span>
      </div>

      <h4 style="font-weight: bold; margin-top: 20px;">
        Choose recipient's address
      </h4>
      <a-select
          ref="select"
          v-model:value="address"
          :options="adressesList"
          style="width: 100%; white-space: break-spaces; word-break: break-word;"
          @select="onSelectAddress"
      />
      <h4 style="font-weight: bold; margin-top: 20px;">
        Order attachments (total: ${{ total }})
      </h4>
      <div v-for="(product, i) in products" :key="i" style="margin-bottom: 5px;">
          <a-card size="small">
            <template v-if="products.length > 1" #extra>
              <CloseOutlined @click="removeProduct(i)" />
            </template>
                <a-input style="margin: 5px 0 5px 0" v-model:value="product.name" placeholder="Parcel contents" />
                <a-input style="margin: 5px 0 5px 0" v-model:value="product.link" placeholder="Link" v-if="parcelType === 'PACKAGE'" />
                <a-input-group style="margin: 5px 0 5px 0" size="medium" v-if="parcelType === 'PACKAGE'">
                  <a-row :gutter="8">
                    <a-col :span="12">
                      <a-input-number :min="1" v-model:value="product.quantity" addon-after="qty"></a-input-number>
                    </a-col>
                    <a-col :span="12">
                      <a-input-number :min="0" v-model:value="product.cost" addon-after="$"></a-input-number>
                    </a-col>
                  </a-row>
                </a-input-group>
          </a-card>
      </div>
      <a-row style="margin: 5px 0 5px 0">
        <a-col :span="24">
          <a-button type="dashed" block @click="addProduct">
            <PlusSquareOutlined />Add Product
          </a-button>
        </a-col>
      </a-row>
      <a-row style="text-align: center">
        <a-col :span="24" :gutter="40" style="margin-top: 20px; margin-bottom: 70px;">
          <a-button type="primary" size="large" @click="addParcel" :loading="loading">Create</a-button>
        </a-col>
      </a-row>

    </a-card>
  </a-col>
</a-row>

</template>
<script setup>
import { PlusSquareOutlined, CloseOutlined, CopyOutlined, EnvironmentOutlined } from '@ant-design/icons-vue';
import LoadingSpiner from "@/components/Loading-Spiner.vue";
</script>
<script>
import { ref } from 'vue';
import api from "@/services/api";
import { message } from "ant-design-vue";
export default {
  name: 'addParcel',
  data: () => ({
    isShowMap: false,
    recipient: ref(''),
    firstMileTrack: '',
    selectedRecipient: {},
    address: '',
    selectedAddress: {},
    deliveryType: '1',
    warehouses: [
      {
        value: '2',
        label: 'Wilmington',
      },
    ],
    warehouse: '',
    countries: [
      {
        value: 'RU',
        label: 'Russian Federation',
      },
    ],
    country: '',
    products: [
      {
        name: "",
        link: "",
        quantity: 1,
        cost: 0,
      },
    ],
    recipientsList: [],
    adressesList: [],
    loading: false,
    fetchLoading: false,
    parcelTypes: [
      {
        value: 'PACKAGE',
        label: 'Package',
      },
      {
        value: 'DOCUMENT',
        label: 'Document',
      },
    ],
    parcelType: 'PACKAGE',
    user: {}
  }),
  computed: {
    total () {
      let sum = 0;
      this.products.forEach( e => sum += e.cost * e.quantity );
      return sum;
    },
    sendInfo() {
      if (this.user.id) {
        return {
          user: `#${this.user.id} ${this.user.fullName}`,
          dist: '402 7TH AVE',
          city: 'WILMINGTON, DE 19805'
        };
      } else {
        return {
          user: '',
          dist: '402 7TH AVE',
          city: 'WILMINGTON, DE 19805'
        };
      }

    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(`#${this.user.id} ${this.user.fullName} 402 7TH AVE WILMINGTON, DE 19805`)
        .then(() => {
           message.success('Address copied to clipboard');
        })
        .catch(err => {
          console.error(err);
           message.error('Failed to copy address');
        });
    },
    addProduct() {
      this.products.push({
        name: "",
        link: "",
        quantity: 1,
        cost: 0,
      });
    },
    removeProduct(index) {
      this.products.splice(index, 1);
    },
    
    async addParcel() {
      if (!this.products || this.products.length === 0 || !this.products[0].name ||
        !this.selectedRecipient || !this.selectedAddress || !this.recipient) {
        message.error('Please fill all required fields!');
        this.loading = false;
        return;
      } else if (this.deliveryType === "2" && this.firstMileTrack.length === 0) {
        message.error('Please enter first mile track number!');
        this.loading = false;
        return;
      } else if (this.parcelType === "PACKAGE") {
        let hasError = false;
        this.products.forEach(product => {
          if (product.cost === 0 || !product.cost) {
            message.error('Please enter cost for all products!');
            hasError = true;
          }
        });
        if (hasError) {
          this.loading = false;
          return;
        }
      }

        this.loading = true;
        this.products.forEach(product => {
          product.cost === '' ? product.cost = 0 : product.cost
        });
        let parcel = {
          name: this.products[0].name,
          parcelType: this.parcelType,
          products: this.products,
          recipientAddressTemplateId: this.selectedAddress.id,
          recipientTemplateId: this.selectedRecipient.id,
          stockId: this.warehouse,
          firstMileTrack: (this.deliveryType === "2")? this.firstMileTrack :null
        };
        try {
          const response = await api.post("/parcel", JSON.stringify(parcel));
          if (response.status === 200) {
            message.success('Order has been saved!', 5);
            this.loading = false;
            this.$router.push('/myParcels');
          }
        } catch (error) {
          console.error("Error adding parcel:", error);
          if (error.message === "Token not found") {
            message.error("Not authorized");
          } else {
            message.error("Failed to add parcel!");
          }
          this.loading = false;
        }
    },

    onSearch(input, text) {
      return text.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    },
    onSelect(value, option) {
      this.address = '';
      this.adressesList = [];
      this.selectedRecipient = option;
      option.addresses.forEach( e => {
        this.adressesList.push({
          value: (e.cityAddress)? e.cityAddress.address : e.officeAddress.address,
          label: (e.cityAddress)? e.cityAddress.address : e.officeAddress.address,
          id: e.id
        });
        this.address = this.adressesList[0].value;
        this.selectedAddress = this.adressesList[0];
      });
    },
    onSelectAddress(value, option) {
      this.selectedAddress = option;
    },
    async loadRecipients() {
      try {
        const response = await api.get("/recipient-template");
        if (response.status === 200) {
          response.data.forEach( r => {
            this.recipientsList.push({
              value: r.fullName + " [" + r.phone + "]",
              id: r.id,
              addresses: r.address
            });
          });
        }
      } catch (error) {
        console.error("Error loading recipients:", error);
        message.error("Failed to load recipients!");
      }
    },
    async loadUserInfo() {
      try {
        const response = await api.get("/user");
        if (response.status === 200) {
          this.user = response.data
        }
      } catch (error) {
        console.error("Error loading user info:", error);
        message.error("Failed to load user info!");
      }
    },
  },
  async mounted() {
    try {
      this.fetchLoading = true;
      await this.loadRecipients();
      await this.loadUserInfo();
      this.warehouse = this.warehouses[0].value;
      this.parcelType = this.parcelTypes[0].value;
      this.country = this.countries[0].value;
    } finally {
      this.fetchLoading = false;
    }

  }
}
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.ant-select-item-option-content {
  white-space: pre-line !important;
}
</style>
